import { graphql } from "gatsby";
import sortBy from "lodash/sortBy";
import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import { MainHeading } from "../components/MainHeading";
import { Page } from "../components/Page";
import { Thumbnail } from "../components/Thumbnail";
import { ThumbnailGrid } from "../components/ThumbnailGrid";

type PressPageProps = {
  data: GatsbyTypes.PressImagesQuery;
};

export default function PressPage({ data }: PressPageProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>("");
  const groups = sortBy(data.allPressArticlesJson.group, (group) => {
    switch (group.fieldValue) {
      case "United Brass":
        return -1;
      case "Sonstiges":
        return 1;
    }
    return 0;
  });

  return (
    <Page title="Presse">
      <div className="prose max-w-none mb-8">
        <p>
          Hier findet Ihr eine kleine Auswahl der Zeitungsartikel der
          vergangenen 25 Jahre. Die Artikel stammen zumeist aus den Lokalteilen
          der WAZ und NRZ oder aus dem Stadtteilmagazin "Steeler Kurier". Auch
          die Dankesschreiben unserer (ehemaligen) Schulministerin Barbara
          Sommer für unsere Auftritte für das MSW (Ministerium für Schule und
          Weiterbildung) haben wir hier online gestellt.
        </p>
        <p>
          Solltet Ihr noch Artikel vor allem aus den ersten Jahren der Band
          besitzen, die jetzt noch nicht auf der Homepage sind, lasst es uns
          wissen.
        </p>
      </div>
      {isOpen ? (
        <Lightbox
          mainSrc={currentImage}
          onCloseRequest={() => setIsOpen(false)}
        />
      ) : null}
      {groups.map((project) => (
        <div key={project.fieldValue} className="mb-8">
          <MainHeading>{project.fieldValue}</MainHeading>
          <ThumbnailGrid>
            {project.nodes.map((article) =>
              article.image?.childImageSharp?.gatsbyImageData ? (
                <button
                  key={article.id}
                  onClick={() => {
                    setCurrentImage(article.image?.publicURL || "");
                    setIsOpen(true);
                  }}
                >
                  <Thumbnail
                    imageData={article.image.childImageSharp.gatsbyImageData}
                    alt={article.name || "Missing description"}
                    title={
                      <>
                        {article.name} – <small>{article.date}</small>
                      </>
                    }
                  />
                </button>
              ) : null
            )}
          </ThumbnailGrid>
        </div>
      ))}
    </Page>
  );
}

export const query = graphql`
  query PressImages {
    allPressArticlesJson(sort: { fields: [date], order: DESC }) {
      group(field: project) {
        totalCount
        fieldValue
        nodes {
          id
          name
          project
          date(formatString: "DD.MM.YYYY")
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(aspectRatio: 1, width: 590, placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;
